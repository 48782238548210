.boxCon {
    width: 100%;
}

.list{
    background-color: white;
}
.history-table-wrap {
 width: 95%;
 margin: auto;
 overflow-y: scroll;
 overflow-x: hidden;
 margin-top: 10px;
 background-color: #ffffff;
}

/* 表格代码   */
.table {
 border: 1px solid #dadada;
 border-right: 0;
 border-bottom: 0;
 width: 100%;
 /* margin-left: 1%; */
}

.tr {
 width: 100%;
 display: flex;
 justify-content: space-between;
}

.th,
.td {
 padding: 10px;
 border-bottom: 1px solid #efefef;
 border-right: 1px solid #efefef;
 text-align: center;
 width: 100%;
}

.th1,
.th2,
.td1,
.td2 {
 width: 100%;
}

.th {
 background-color: #efefef;
 font-size: 12px;
 color: black;
  flex:1;
}

.td {
 font-size: 12px;
 color: #4A4A4A;
  flex:1;
}
.btn{
    width: 40%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}
.td4,.th4{
	flex:2;
}