
/* @font-face {
	font-family:"xinzit";
	src: url(../../../public/AlternateBold.ttf);
} */
.content{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

}
.cahchu{
    background-color: #33a453;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu2{
    background-color: #e5a059;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu3{
    background-color: #3f9fe0;
   width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu4{
    background-color: #b76d50;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu5{
    background-color: #6e4ebc;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu6{
    background-color: #1f32e1;
   width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu7{
    background-color: #319dd2;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.cahchu8{
    background-color: #33a453;
    width: 95%;
    margin: 10px auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: center;
}
.top{
    width: 90%;
    margin:10px auto 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
}
.left{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-self: flex-start;
}
.left7{
    width: 90%;
    margin:auto 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.right{
    width: 150px;
}
.tv1{
    text-align: left;
    margin: 5px 0px;
    font-size: 18px;
    color: white;
}
.tv17{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 18px;
    color: white;
    margin-left: 10px;
}
.manyi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.myImg{
    width: 40px;
    height: 40px;
}
.suble{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-self: center;
    margin: 5px 0px;
}
.youh{
    color:white;
    margin-top: 10px;
}
.counth{
    color: white;
    font-size: 20px;
    margin-top: 20px;
}
.suble6{
    color: white;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-self: center;
    margin: 5px 0px;
}
.sum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.sum7{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.zong{
    font-size: 12px;
    color: white;
}
.znum{
    font-size: 36px;
    color: white;
    margin: 10px 0px;
	font-family: "xinzit";
}
.bottom{
    width: 90%;
    margin:0px auto;
    display: flex;
    flex-direction: column;
    justify-content:start;
    align-self: center;
}
.btm7{
    display: flex;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin:0px auto;
}
.bottom7{
    width: 50%;
    margin:0px auto;
    display: flex;
    flex-direction: column;
    justify-content:start;
    align-self: center;
}
.right7{
    width: 50%;
    height: 80px;
    align-self: center;
}
.bottom6{
    width: 100%;
    margin:0px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bt1{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}
.bt2{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}
.btmone{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-self: center;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    color: white;
    margin: 10px 0px;
}
.btmone6{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-self: center;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    color: white;
    margin: 10px 0px;
}
.leiji6{
    /* width: 100%; */
    /* margin-right: 50px; */
}
.leiji{
    margin-right: 50px;
}
.echartsRadar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
}
.echartsPie{
    display: flex;
    justify-content: center;
    height: 280px;
}