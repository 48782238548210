.title{
    margin:10px;
}
.contentd{
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.example{
   margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;  
}
.loading{
    margin: 200px auto;
    text-align: center;
}
.spac{
    width: 100%;
    height: 100px;
}
.jiazai{
    width: 100px;
    height: 100px;
}
.contentcol{
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.tvs{
    font-size: 15px;
}
.peop{
    font-size: 15px;
    color: red;
}
.tvss{
    font-size: 18px;
}
.wg{
    margin-top: 5px;
}
.law{
    margin-top: 8px;
}
.st{
    padding: 10px;
}
.textarea{
    height: 100px;
    border: 1px solid #eee;
}
.btn{
    /* width: 80px; */
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}