.header{
    position: fixed;
    top: 0;
    float: inherit;
    width: 100%;
    background-color: white;
    z-index: 10;
}
.my-list2{
    margin-top: 80px;
    z-index: 9
}
.my-list3{
    background-color: white;
    font-size: 10px;
}