.am-list-body{
    background-color: #e0e0e0;
}
.contentm{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}
.status{
    color: red;
}
.item{
    background-color:#fff;
    margin: 5px 0px;
    margin-top: 5px;
}
.mytab{
   position: fixed;
   top:0;
   float: inherit; 
   border: 1px solid red;
}