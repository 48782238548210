.headertitle{
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 20px;
    margin: 10px;
}
.headerlsit{
    margin-top: 0px;
    margin-bottom: 10px;
}
.headertwo{
    margin-top: 5px;
}
.radiorow{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: space-around;
    height: 30px;
}
.ant-radio{
    margin-right: 10px;
}
.ant-radio-wrapper{
    width: 40%;
    font-size: 18px;
}

.am-list-item .am-input-label.am-input-label-5 {
    width: 100px !important;
}