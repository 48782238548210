.boxCon {
    width: 100%;
}

.list{
    background-color: white;
}
.echat1{
    margin-top: 10px;
}
.pagetitle{
    font-size: 18px;
    text-align: center;
    margin: 10px auto;
}
.nametitle{
    font-size: 15px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.history-table-wrap {
 width: 95%;
 margin: 10px auto;
 overflow-y: scroll;
 overflow-x: hidden;
 margin-top: 10px;
 background-color: #ffffff;
}

/* 表格代码   */
.table {
 border: 1px solid #dadada;
 border-right: 0;
 border-bottom: 0;
 width: 100%;
 /* margin-left: 1%; */
}

.tr {
 width: 100%;
 display: flex;
 justify-content: space-between;
}

.th,
.td {
 padding: 10px;
 border-bottom: 1px solid #efefef;
 border-right: 1px solid #efefef;
 text-align: center;
}



.th {
 background-color: #efefef;
 font-size: 12px;
 color: black;
 flex:1;
}

.td {
 font-size: 12px;
 color: #4A4A4A;
 display: flex;
 justify-content: center;
 align-items: center;
 flex:1;
}
.td1,.th1{
    width: 5%;
}
.td2,.th2{
    width: 70%;
}
.td3,.th3{
    width: 5%;
}
.td4,.th4{
    width: 20%;
}
.btn{
    width: 40%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}

.nodata {
    width: 100%;
    height: 200px;
    line-height: 200px;
    text-align: center;
    font-size: 16px;
}
.td4,.th4{
	flex:2;
}

.zhenggai{
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.first{
    width: 100%;
    font-size: 14px;
}
.two{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.two1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.two3{
    margin-top: 20px;
     font-size: 18px;
}

.two2{
    font-size: 20px;
    color: red;
}


.parent{
    margin: 40px 10px;
}
.p1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.p22{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}
.ps1{
    width: 200px;
    height: 20px;
    background-color: chartreuse;
}
.ps2{
    width: 50px;
    height: 20px;
    background-color:rebeccapurple;
}
.p111{
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin-right: 20px;
}

.bt2{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
}
.manyi{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.myImg{
    width: 60px;
    height: 60px;
}
.you{
    color:black;
    margin-top: 5px;
}
.count{
    color: black;
    font-size: 18px;
    margin-top: 10px;
}
.you1{
    font-size: 25;
}