.boxCon {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.head{
    position:fixed;
    top:0px;
    float:inherit;
    width:100%
}
.list{
    background-color: white;
}
.history-table-wrap {
 width: 95%;
 margin-top: 225px;
 background-color: #ffffff;
}

/* 表格代码   */
.table {
 border: 1px solid #dadada;
 border-right: 0;
 border-bottom: 0;
 width: 50%;
 table-layout: fixed;
 /* margin-left: 1%; */
}

.tr {
 width: 100%;
 display: flex;
 justify-content: space-between;
}

.th,
.td {
 padding: 10px;
 border-bottom: 1px solid #efefef;
 border-right: 1px solid #efefef;
 text-align: center;
 /* width: 100%; */
}

.th1,
.th2,
.td1,
.td2 {
 /* width: 100%; */
}

.th {
 background-color: #efefef;
 font-size: 12px;
 color: black;
  flex:1;
}

.td {
 font-size: 12px;
 color: #4A4A4A;
  flex:1;
}
.btn{
    width: 40%;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 20px;
}
.td4,.th4{
	flex:2;
}

.jiazai{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    font-size: 15px;
    color: black;
}